import { StyledEngineProvider } from '@mui/material/styles'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Link } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { setLinkComponent } from '~/components/any-link'
import DatadogRum from '~/components/datadog-rum'
import ExpirationWarning from '~/components/expiration-warning'
import Head from '~/components/head'
import { initFeatureFlagUtilities } from '~/config/feature-flags'
import GlobalStyle from '~/styles/global-style'
import theme from '~/styles/theme'
import { setUploadApiUrl, setUploadImageUrl } from '~/utils/cloudinary'
import { setLocale, setAvailableLocales } from '~/utils/locale'

import {
  LOCALE,
  AVAILABLE_LOCALES,
  CLOUDINARY_IMAGE_URL,
  CLOUDINARY_API_URL,
} from './config'
import Routes from './routes'
import { createAndInitializeStore } from './store'
import { setStoreInstance } from './store-instance'

import './i18n'
import './index.scss'

setLinkComponent(Link)
setLocale(LOCALE)
setAvailableLocales(AVAILABLE_LOCALES)
setUploadApiUrl(CLOUDINARY_API_URL)
setUploadImageUrl(CLOUDINARY_IMAGE_URL)

const store = createAndInitializeStore()

setStoreInstance(store)

initFeatureFlagUtilities(store)

const App = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <BrowserRouter>
          <div className="amp-base">
            <DatadogRum />
            <Head />
            <Routes />
            <ExpirationWarning />
          </div>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
)

export default App
