/**
 * Component to handle displaying pages which require authentication.
 *
 * Will check if the user is logged in, allowing access if they are or
 * redirecting if they are not.
 */
import queryString from 'query-string'
import React, { Component } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FraudCheckService from '~/components/fraud-check-service'
import { UNCONFIRMED_PATHS } from '~/config'
import { useAppDispatch } from '~/hooks/use-app-dispatch'
import useMemoizedSelector from '~/hooks/use-memoized-selector'

import { AuthError, authenticationFailed } from '../actions/auth'
import { replace } from '../actions/navigation'
import { loadProfile } from '../actions/profile'

import type { AuthErrorCode } from '../actions/auth'
import type { ReactNode } from 'react'
import type { Location, NavigateFunction } from '~/types'

function canAccessWithoutConfirmation(path: string) {
  return !!UNCONFIRMED_PATHS.find((unconfirmedPath) => {
    if (unconfirmedPath instanceof RegExp) {
      return unconfirmedPath.test(path)
    } else {
      return path === unconfirmedPath
    }
  })
}

function buildRedirect(location) {
  return `?redirect_uri=${encodeURIComponent(
    encodeURIComponent(location.pathname + location.search),
  )}`
}

type SelectorProps = {
  authenticated: boolean
  isConfirmed: boolean
  profileLoaded: boolean
  profileLoadFailed: boolean
}

type Props = SelectorProps & {
  children: ReactNode
  dispatch: (...args: Array<any>) => any
  location: Location
  navigate: NavigateFunction
}

class Auth extends Component<Props> {
  componentDidMount() {
    this.redirect(this.props)
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.profileLoaded !== this.props.profileLoaded ||
      prevProps.profileLoadFailed !== this.props.profileLoadFailed ||
      prevProps.authenticated !== this.props.authenticated ||
      prevProps.isConfirmed !== this.props.isConfirmed
    ) {
      this.redirect(this.props)
    }
  }

  redirect(props) {
    if (this.waitingForProfile(props)) {
      this.props.dispatch(loadProfile())
      return
    }

    const { location, authenticated, isConfirmed } = props

    if (!authenticated) {
      let search = ''

      if (location.pathname !== '/logout') {
        search = this.buildRedirectQueryParams(location)
      }

      this.props.dispatch(replace('/login' + search))
    } else if (
      !isConfirmed &&
      !canAccessWithoutConfirmation(location.pathname)
    ) {
      this.props.dispatch(replace('/register/confirm'))
    }
  }

  buildRedirectQueryParams(location) {
    return this.handleOAuthFailure(location) || buildRedirect(location)
  }

  get oAuthErrorCode(): AuthErrorCode | null | undefined {
    const error = queryString.parse(this.props.location.search).error
    if (!error) return undefined
    if (error === 'not_registered') return AuthError.SOCIAL_NOT_REGISTERED
    else return AuthError.UNKNOWN
  }

  handleOAuthFailure(location) {
    if (this.oAuthErrorCode) {
      this.props.dispatch(authenticationFailed(this.oAuthErrorCode))
      const queryWithoutError = queryString.parse(location.search)
      delete queryWithoutError.error
      return `?redirect_uri=${location.pathname + `?${queryString.stringify(queryWithoutError)}`}`
    }
  }

  waitingForProfile(props) {
    return !props.profileLoaded && !props.profileLoadFailed
  }

  willRedirect() {
    const { authenticated, isConfirmed, location } = this.props
    return (
      !authenticated ||
      (!isConfirmed && !canAccessWithoutConfirmation(location.pathname))
    )
  }

  render() {
    if (this.waitingForProfile(this.props) || this.willRedirect()) return null
    return (
      <>
        <FraudCheckService />
        {this.props.children}
      </>
    )
  }
}

const AuthContainer = (props: Props) => {
  return <Auth {...props} location={useLocation()} />
}

/**
 * Helper to convert the global state tree into a simpler object that only
 * contains the data we are interested in.
 */
function mapStateToProps(state): SelectorProps {
  const {
    auth: { authenticated },
    profile,
  } = state
  return {
    authenticated,
    isConfirmed: !!profile.confirmedAt,
    profileLoaded: !!profile.loaded,
    profileLoadFailed: !!profile.loadFailed,
  }
}

export default (props) => {
  const selectorProps = useMemoizedSelector(mapStateToProps)
  const composedProps = {
    ...props,
    location: useLocation(),
    navigate: useNavigate(),
    dispatch: useAppDispatch(),
  }

  return <AuthContainer {...selectorProps} {...composedProps} />
}
