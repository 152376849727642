import classNames from 'classnames'
import React, { Component } from 'react'

import Button from '~/components/button'
import { LogInLink } from '~/components/global-header/components'
import GuestImpersonationBanner from '~/components/guest-impersonation-banner'
import { i18n } from '~/i18n'

import HeaderCart from './partials/cart'
import CurrentGuest from './partials/current-guest'
import HeaderLogo from './partials/logo'
import HeaderNavIcon from './partials/menu-icon'
import Navigation from './partials/navigation'
import CurrencyLocale from '../currency-locale'
import CurrencyLocaleModal from '../currency-locale-modal'

import type { NavSocialLink } from './partials/navigation'
import type { Currency, Locale, Links, GuestProfile } from '../../types'

import './index.scss'

// Partials

const DEFAULT_LOCALE_LINKS: Links = [
  {
    text: 'English',
    to: '/en/',
  },
  {
    text: 'FRANÇAIS',
    to: '/fr/',
  },
]

const RenderCartIcon = ({ cartLink, cartItemCount }) => {
  return (
    <Button
      type="unstyled"
      to={cartLink}
      className="cart-checkout"
      data-testid="cart-button"
    >
      <HeaderCart cartItemCount={cartItemCount} />
    </Button>
  )
}

const RenderMenuIcon = ({ toggleNavBar, menuNavOpen, collapsed }) => {
  const menuIconClassNames = classNames('menu-icon-wrap', {
    'open-menu': !menuNavOpen,
  })

  return (
    <Button
      type="unstyled"
      onClick={toggleNavBar}
      className={menuIconClassNames}
      data-testid="nav-menu-button"
      aria-label={i18n.t('components.global_header.menu_alt_text')}
    >
      <HeaderNavIcon isCollapsed={collapsed} />
    </Button>
  )
}

type ResponsiveLink = {
  text: {
    mobile: string
    desktop: string
  }
  to: string
}

export type Props = {
  currency: Currency
  locale: Locale
  showMenu: boolean
  showLogin?: boolean
  homeLink?: string
  cartLink: string
  currentGuestProfile?: GuestProfile
  myAccountPath?: string
  content: {
    shopPasses?: ResponsiveLink
    navigation: {
      links: Links
    }
    localeLinks?: {
      links: Links
    }
    bottomLinks: {
      links: Links
    }
    socialLinks: {
      links: NavSocialLink[]
    }
    currentGuest?: {
      photoAltText: string
      greetingText: string
    }
  }
  cartItemCount: number
  onCurrencyAndLocaleChange: (arg0: Currency, arg1: Locale) => void
}

type State = {
  collapsed: boolean
  currencyLocaleModalOpen: boolean
}

export default class GlobalHeader extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      collapsed: true,
      currencyLocaleModalOpen: false,
    }
  }

  openCurrencyLocaleModal = () => {
    this.setState({
      currencyLocaleModalOpen: true,
    })
  }

  closeCurrencyLocaleModal = () => {
    this.setState({
      currencyLocaleModalOpen: false,
    })
  }

  toggleNavbar = () => {
    document.body &&
      document.body.classList.toggle('navigation-open', this.state.collapsed)

    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  onCurrencyAndLocaleChange = ({
    currency,
    locale,
  }: {
    currency: Currency
    locale: Locale
  }) => {
    this.props.onCurrencyAndLocaleChange(currency, locale)
  }

  getHomeLink() {
    return this.props.homeLink || `/${this.props.locale}`
  }

  renderShopPassesLink() {
    const content = this.props.content

    if (content.shopPasses) {
      return (
        <div className="shop-passes-wrap">
          <Button
            type="unstyled"
            className="shop-passes"
            to={content.shopPasses.to}
          >
            <span className="mobile-text">
              {content.shopPasses.text.mobile}
            </span>
            <span className="desktop-text">
              {content.shopPasses.text.desktop}
            </span>
          </Button>
        </div>
      )
    }
  }

  render() {
    const {
      showMenu,
      cartLink,
      content,
      cartItemCount,
      currentGuestProfile,
      showLogin,
    } = this.props

    const openMenu = this.state.collapsed

    const globalClasses = classNames({
      'in-checkout': !showMenu,
    })

    return (
      <div id="amp-global-header" className={globalClasses}>
        <GuestImpersonationBanner />
        <div className="navbar">
          <RenderMenuIcon
            toggleNavBar={this.toggleNavbar}
            menuNavOpen={openMenu}
            collapsed={this.state.collapsed}
          />
          <HeaderLogo href={this.getHomeLink()} />

          <div className="header-right-container">
            {showMenu && (
              <CurrencyLocale
                currency={this.props.currency}
                locale={this.props.locale}
                onClick={this.openCurrencyLocaleModal}
                white
              />
            )}

            {showLogin && <LogInLink />}

            {this.state.currencyLocaleModalOpen && (
              <CurrencyLocaleModal
                currency={this.props.currency}
                locale={this.props.locale}
                onClose={this.closeCurrencyLocaleModal}
                onSubmit={this.onCurrencyAndLocaleChange}
              />
            )}
            {currentGuestProfile ? (
              <CurrentGuest
                currentGuestProfile={this.props.currentGuestProfile}
                myAccountPath={this.props.myAccountPath}
                currentGuestContent={this.props.content.currentGuest}
              />
            ) : (
              this.renderShopPassesLink()
            )}
            <div className="cart-wrap">
              <RenderCartIcon
                cartItemCount={cartItemCount}
                cartLink={cartLink}
              />
            </div>
          </div>
        </div>
        <Navigation
          currency={this.props.currency}
          locale={this.props.locale}
          links={content.navigation.links}
          bottomLinks={content.bottomLinks.links}
          socialLinks={content.socialLinks.links}
          localeLinks={
            content.localeLinks
              ? content.localeLinks.links
              : DEFAULT_LOCALE_LINKS
          }
          collapsed={this.state.collapsed}
          onCurrencyLocaleClick={this.openCurrencyLocaleModal}
          onClickLink={this.toggleNavbar}
        />
      </div>
    )
  }
}
