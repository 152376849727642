import React from 'react'

import { Banner, BannerContent } from './banner.styles'

import type { ReactNode } from 'react'

export type Props = {
  bgColor?: string
  children: ReactNode
  msgType?: string
}

export default ({ bgColor, children, msgType, ...otherProps }: Props) => {
  return (
    <Banner $msgtype={msgType || ''} {...otherProps}>
      <BannerContent>{children}</BannerContent>
    </Banner>
  )
}
