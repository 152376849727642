import classNames from 'classnames'
import React, { Component } from 'react'

import Button from '../button'
import Icon from '../icon'

import './index.scss'

import type { ReactNode } from 'react'
import type { MessageTypes } from '~/actions/flash-message'
import type { Glyphs } from '~/components/icon'

const ANIMATION_TIME = 1000
const ANIMATION_DELAY = 30
const AUTO_HIDE_DELAY = 4000

type Props = {
  type: MessageTypes
  children: ReactNode
  glyph: Glyphs
  onClose?: () => void
  persistent?: boolean
  autoHide?: boolean
}

type State = {
  show: boolean
}

export default class FlashMessage extends Component<Props, State> {
  static defaultProps = {
    glyph: 'alert' as const,
  }
  onShowTimeoutId: number | null | undefined
  onCloseTimeoutId: number | null | undefined
  autoHideTimeoutId: number | null | undefined

  constructor(props: Props) {
    super(props)
    this.state = {
      show: false,
    }
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    if (this.onShowTimeoutId) {
      window.clearTimeout(this.onShowTimeoutId)
    }

    if (this.onCloseTimeoutId) {
      window.clearTimeout(this.onCloseTimeoutId)
    }

    if (this.autoHideTimeoutId) {
      window.clearTimeout(this.autoHideTimeoutId)
    }
  }

  init() {
    if (this.onShowTimeoutId) return
    this.onShowTimeoutId = window.setTimeout(() => {
      this.setState({
        show: true,
      })
      delete this.onShowTimeoutId
    }, ANIMATION_DELAY)

    if (this.props.autoHide) {
      this.autoHideTimeoutId = window.setTimeout(() => {
        this.onClose()
      }, AUTO_HIDE_DELAY)
    }
  }

  onClose = () => {
    const { onClose } = this.props
    if (this.onCloseTimeoutId || !onClose) return
    this.setState({
      show: false,
    })
    this.onCloseTimeoutId = window.setTimeout(() => {
      onClose()
      delete this.onCloseTimeoutId
    }, ANIMATION_TIME)
  }

  renderCloseButton() {
    if (this.props.persistent) return
    return (
      <Button
        type="icon"
        id="flash-message-close"
        className="close-btn"
        glyph="close"
        onClick={this.onClose}
      />
    )
  }

  render() {
    const { children, glyph, type } = this.props
    return (
      <div className={classNames('amp-flash-message', type, this.state)}>
        {this.renderCloseButton()}
        <div className="content">
          <Icon glyph={glyph} />
          {children}
        </div>
      </div>
    )
  }
}
