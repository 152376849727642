import { connect } from 'react-redux'

import { setCurrencyAndLocale } from '~/actions/cart'

import GlobalHeader from './global-header.component'

import type { Dispatch } from 'redux'
import type { AppActions } from '~/actions/types'
import type { Currency, Locale } from '~/types'

const mapStateToProps = () => ({})

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => ({
  onCurrencyAndLocaleChange: (currency: Currency, locale: Locale) =>
    // @ts-expect-error TS2345
    dispatch(setCurrencyAndLocale(currency, locale)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader)
